import Vue from 'vue'
import VueRouter from 'vue-router'

/** 导入element-UI信息提示组件 */
import {
  Message
} from 'element-ui'
import store from '../store'


Vue.use(VueRouter)

/** 按需导入后台系统功能组件 */
const routes = [{
  path: '',
  redirect: '/login'
},
{
  path: '/login',
  component: () => import('../components/login')
},

{
  path: '',
  redirect: '/login'
},

{
  path: '/login',
  component: () => import('../components/login')
},
{
  path: '/register',
  component: () => import('../components/register')
},
{
  path: '/admin',
  component: () => import('../components/admin'),
  children: [
    {
      path:'/admin/pinzhong',
      component:()=>import('../components/admin/pinzhong.vue')
    },
    {
      path:'/admin/bigdata',
      component:()=>import('../components/admin/bigdata.vue')
    },
    {
      path:'/admin/register',
      component:()=>import('../components/admin/register/register.vue')
    },
    {
      path:'/admin/qyinfo',
      component:()=>import('../components/admin/qyinfo/qyinfo.vue')
    },
    {
      path:'/admin/smtz',
      component:()=>import('../components/admin/smtz.vue')
    }

  ]
},
{
  path: '/company',
  component: () => import('../components/company'),
  children: [
    {
      path:'/company/pinzhong',
      component:()=>import('../components/company/pinzhong.vue')
    },
    {
      path:'/company/pinzhongku',
      component:()=>import('../components/company/pinzhong/pinzhong.vue')
    },{
      path:'/company/yyzz',
      component:()=>import('../components/company/yyzz/yyzz.vue')
    },
    {
      path:'/company/bigdata',
      component:()=>import('../components/company/bigdata.vue')
    },
    {
      path:'/company/jyxkz',
      component:()=>import('../components/company/jyxkz/jyxkz.vue')
    },
    {
      path:'/company/qyinfo',
      component:()=>import('../components/company/qyinfo/qyinfo.vue')
    },
    {
      path:'/company/editqyinfo',
      component:()=>import('../components/company/qyinfo/models/editqyinfo.vue')
    },
    {
      path:'/company/detail',
      component:()=>import('../components/company/detail.vue')
    },
    {
      path:'/company/shengchandetail',
      component:()=>import('../components/company/shengchandetail.vue')
    },
    {
      path:'/company/print',
      component:()=>import('../components/company/print.vue')
    },
    {
      path:'/company/shengchan',
      component:()=>import('../components/company/shengchan.vue')
    },
    {
      path:'/company/symprint',
      component:()=>import('../components/company/symprint.vue')
    }
  ]
} 

]
const gov=[
  
]
const company =[
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('../components/login')
  },
  
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to:将要访问的路径  from:从哪里过来的   next:放行函数-> next()   next('跳转路径')
  /** 登录,初始化页面不需要拒绝 */
  if (to.path == '/login' ||to.path=='/register') {
    return next()
  }

  /** 其他页面需要登录 */
  const token = window.sessionStorage.getItem('token')
  const auth = sessionStorage.getItem('auth')
  console.log(auth)
  if (token == undefined || token == '') {
    Message({
      message: '用户未登录，请重新登录',
      type: 'warning',
      showClose: true,
      center: true
    })
    return next('/login')
  }else{
    console.log(to.path)
    next()
  }
  
    
    
  


}) 


export default router
