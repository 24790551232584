import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
import store from './store'
// 导入axios数据交互请求
import axios from 'axios'
import'../src/assets/css/font.css'
import dataV from '@jiaminghi/data-view'
import VCharts from 'v-charts'


Vue.use(VCharts)
Vue.use(dataV)
// 使用axios
axios.defaults.baseURL = 'https://zz.zz.jiaotu.cc/'
Vue.prototype.$http = axios
Vue.config.productionTip = false
axios.interceptors.request.use(
  config => {
  // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = sessionStorage.getItem('token')
    if (!config.headers.hasOwnProperty('Authorization') && token) {
      config.headers.Authorization = 'bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  })
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
